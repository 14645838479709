<template>
	<lassoLayout class="page-login">
		<div class="page_form_layout full_page">
			<v-container>
				<div class="wrap_form_layout">
					<div class="form-card">
						<h2 class="login">Profile Basics</h2>
						<div class="form">
							<div class="full_name">
								<label>Full Name <span class="note">(not your artist name)</span></label>
								<input autofocus type="text" v-model="fullName" autocomplete="off">
								<div class="error-msg" v-if="!$v.fullName.isNameValid">Only alphanumeric characters, hyphen and space</div>
								<div class="error-msg" v-if="!$v.fullName.maxLength">30 characters max</div>
							</div>
							<div class="location">
								<label>Location</label>
								<vue-google-autocomplete
									id="location"
									classname="form-control"
									placeholder="City, State"
									types="(cities)"
									v-on:keyup="keypressLocation"
									v-on:placechanged="getAddressData">
								</vue-google-autocomplete>
								<div class="error-msg" v-if="!$v.location.isLocationValid">Only alphanumeric characters, hyphen and space</div>
								<div class="error-msg" v-if="!$v.location.maxLength">200 characters max</div>
							</div>
						</div>

						<button v-if="!$v.fullName.$invalid" type="button" class="c2a_btn" v-ripple @click="updateProfile"
								:class="{'loading': loader}" :disabled="loader">
							Continue
							<v-progress-circular :width="2" indeterminate></v-progress-circular>
							<span class="arrow">
								<span class="line_svg"></span>
								<svg width="7" height="10" viewBox="0 0 7 10" fill="none">
									<path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
								</svg>
							</span>
						</button>
						<button v-else type="button" class="c2a_btn" disabled>Continue</button>
					</div>
				</div>
			</v-container>
		</div>
	</lassoLayout>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {maxLength, alphaNum} from 'vuelidate/lib/validators';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import {helpers} from 'vuelidate/lib/validators';
import lassoLayout from "@/layouts/lassoLayout.vue";

export default {
	name: 'profile-basics',
	components: {
		lassoLayout,
		VueGoogleAutocomplete
	},
	data() {
		return {
			loader: false,
			fullName: '',
			location: '',
		}
	},
	watch: {
		'$route'(to, from) {
			this.getProfileData();
		}
	},

	// Validations
	validations: {
		fullName: {
			isNameValid: helpers.regex('isNameValid', /^[A-Za-z0-9-.,\sа-яА-Яєіїґё]*$/i),
			maxLength: maxLength(30)
		},
		location: {
			isLocationValid: helpers.regex('isLocationValid', /^[A-Za-z0-9-.,\sа-яА-Яєіїґё]*$/i),
			maxLength: maxLength(200)
		}
	},
	computed: {
		...mapGetters(['userID', 'AUTH_DATA', 'USER', 'PROFILE']),
	},
	mounted() {
		this.getProfileData();
	},
	methods: {
		...mapActions(['CHANGE_USER_DATA', 'GET_PROFILE']),
		keypressLocation(event) {
			this.location = event.target.value;
		},
		getAddressData: function (addressData, placeResultData) {
			if (addressData.country === 'United States') {
				this.location = `${addressData.locality},  ${addressData.administrative_area_level_1}, ${addressData.country}`;
			} else {
				this.location = `${addressData.locality}, ${addressData.country}`;
			}
		},

		updateProfile() {
			this.loader = true;

			if (!this.location) {
				this.location = 'Planet Earth';
			}
			if (!this.fullName) {
				this.fullName = this.AUTH_DATA.userName.split('@')[0];
			}
			const userData = {
				id: this.userID,
				address: this.location.trim(), // TODO: rename address to location ~backend~
				fullName: this.fullName.trim()
			}
			this.CHANGE_USER_DATA(userData)
				.then(() => {
					this.$router.push({name: 'memberships'})
						.catch(() => {
							this.loader = false;
						});
				})
				.catch(err => {
					console.error(`updateProfile, CHANGE_USER_DATA ${err}`);
					this.loader = false;
				});
		},
		getProfileData() {
			this.GET_PROFILE()
				.catch(err => {
					console.error(`profile-basics, GET_PROFILE ${err}`);
				});
		}
	}
}
</script>

<style lang="scss">
@import '@/assets/styles/pages/_login.scss';

.page-login {
	.form {
		margin: 24px 0;

		.full_name, .location {
			margin-bottom: 24px;
			position: relative;

			&:last-child {
				margin-bottom: 0;
			}
		}

		label {
			color: $label-color;
			font-size: 14px;
			font-weight: 500;
			display: block;
			margin-bottom: 8px;

			.note {
				color: rgba(255, 255, 255, 0.4);
				font-weight: normal;
				font-size: 13px;
			}
		}

		input, .form-control {
			background-color: $input-bg;
			color: white;
			border: 1px solid rgba(255, 255, 255, 0.4);
			border-radius: 4px;
			padding: 12px;
			width: 100%;
			font-size: 15px;
			@include input-transition;
			
			&::placeholder {
				color: rgba(255, 255, 255, 0.4);
			}
			
			&:focus {
				outline: none;
				border-color: rgba(135, 206, 250, 0.7);
			}
		}

		.error-msg {
			color: $error-color;
			font-size: 12px;
			margin-top: 4px;
		}
	}
}
</style>